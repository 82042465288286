<template>
  <div class="document-item">
    <v-row align="center">
      <v-col cols="4">
        <div class="d-flex align-center">
          <div class="document-item__type-icon">
            <v-icon color="primary">{{ typeIcon }}</v-icon>
          </div>
          <div class="document-item__title">
            <a :href="doc.local_filename ? doc.local_filename : '#'">{{ doc.filename }}</a>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col col="6" class="text-right py-0">{{ doc.size | mb }} mb</v-col>
          <v-col col="6" class="py-0">{{ doc.file_type | fileFormat }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row justify="end">
          <v-col cols="6" class="py-0">
            <v-btn
              class="document-item__action-icon"
              :loading="editLoading"
              color="primary"
              text
              icon
              @click="
                documentDialog = true;
                currentMode = mode[0];
              "
            >
              <IconDocEdit />
            </v-btn>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-btn
              class="document-item__action-icon"
              :loading="updateLoading"
              color="primary"
              text
              icon
              @click="
                documentDialog = true;
                currentMode = mode[1];
              "
            >
              <IconDocUpdate />
            </v-btn>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-btn
              class="document-item__action-icon"
              :loading="deleteLoading"
              color="primary"
              text
              icon
              @click="deleteDocument"
            >
              <IconDelete />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ChangeDocumentModal
      :mode="currentMode"
      :show="documentDialog"
      :document="doc"
      @closeModal="documentDialog = false"
      @updateDocument="updateDocument"
    />
  </div>
</template>

<script>
import { DocumentService } from "@/services/documents.service.js";

export default {
  name: "DocumentItem",
  props: {
    doc: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    IconDocEdit: () => import("~cp/Icons/IconDocEdit"),
    IconDocUpdate: () => import("~cp/Icons/IconDocUpdate"),
    IconDelete: () => import("~cp/Icons/IconDelete"),
    ChangeDocumentModal: () =>
      import("../ChangeDocumentModal/ChangeDocumentModal"),
  },
  filters: {
    mb(value) {
      if (!value) return "0";
      return (value / 1048576).toFixed(2);
    },
    fileFormat(value) {
      if (!value) return "-";
      return value === "octet-stream" ? "heic" : value;
    },
  },
  data() {
    return {
      deleteLoading: false,
      editLoading: false,
      updateLoading: false,
      documentDialog: false,
      mode: ["edit", "update"],
      currentMode: "edit",
    };
  },
  computed: {
    typeIcon() {
      switch (true) {
        case this.doc.file_type === "png":
        case this.doc.file_type === "jpg":
        case this.doc.file_type === "jpeg":
        case this.doc.file_type === "octet-stream":
          return "mdi-file-image-outline";
        default:
          return "mdi-file-document-outline";
      }
    },
  },
  methods: {
    deleteDocument() {
      this.deleteLoading = true;
      DocumentService.deleteDocumentById(this.doc.id)
        .then(() => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.delete")
          );
          this.$emit("deleteDocument", this.index);
        })
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.deleteLoading = false));
    },
    updateDocument({ fileName, file }) {
      !file ? (this.editLoading = true) : (this.updateLoading = true);
      DocumentService.updateDocumentById(this.doc.id, { fileName, file })
        .then(() => this.$emit("updateDocument"))
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() =>
          !file ? (this.editLoading = true) : (this.updateLoading = true)
        );
    },
  },
};
</script>